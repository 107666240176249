import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
  ,
  {
    path: '/study',
    name: 'Study',
    component: () => import('../views/Study.vue')
  },
  {
    path: '/statistic',
    name: 'Statistic',
    component: () => import('../views/Statistic.vue')
  },

  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/Index.vue')
  },
  {
    path: '/admin/chapterMenu',
    name: 'chapterMenu',
    component: () => import('../views/admin/ChapterMenu.vue')
  },
  {
    path: '/admin/chapterContentEdit',
    name: 'chapterContentEdit',
    component: () => import('../views/admin/ChapterContentEdit.vue')
  }
  ,
  {
    path: '/admin/batchCorrect',
    name: 'BatchCorrect',
    component: () => import('../views/admin/BatchCorrect.vue')
  },
  {
    path: '/admin/statisticsClass',
    name: 'StatisticsClass',
    component: () => import('../views/admin/StatisticsClass.vue')
  },
  {
    path: '/admin/statisticsStu',
    name: 'StatisticsStu',
    component: () => import('../views/admin/StatisticsStu.vue')
  },
  {
    path: '/admin/statisticsStuPeriod',
    name: 'StatisticsStuPeriod',
    component: () => import('../views/admin/StatisticsStuPeriod.vue')
  },
  {

    path: '/room',
    name: 'Room',
    component: () => import('../views/Room.vue')
  },
  {

    path: '/roomStatistics',
    name: 'RoomStatistics',
    component: () => import('../views/RoomStatistics.vue')
  },
  {
    path: '/admin/recomputeScore',
    name: 'RecomputeScore',
    component: () => import('@/views/admin/RecomputeScore.vue')
  },

  {
    path: '/admin/editQuiz',
    name: 'EditQuiz',
    component: () => import('@/views/admin/EditQuiz.vue')
  },

  {
    path: '/admin/section',
    name: 'Section',
    component: () => import('@/views/admin/Section.vue')
  },

  {
    path: '/admin/addCourse',
    name: 'AddCourse',
    component: () => import('@/views/admin/AddCourse.vue')
  }
  ,
  {
    path: '/admin/manageCourse',
    name: 'ManageCourse',
    component: () => import('@/views/admin/ManageCourse.vue')
  }
  ,
  {
    path: '/admin/addClass',
    name: 'AddClass',
    component: () => import('@/views/admin/AddClass.vue')
  }
  ,
  {
    path: '/admin/manageClass',
    name: 'ManageClass',
    component: () => import('@/views/admin/ManageClass.vue')
  }
  ,
  {
    path: '/admin/importToClass',
    name: 'ImportToClass',
    component: () => import('@/views/admin/ImportToClass.vue')
  }
  ,
  {
    path: '/admin/manageClassStudent',
    name: 'ManageClassStudent',
    component: () => import('@/views/admin/ManageClassStudent.vue')
  },
  {
    path: '/admin/searchStudent',
    name: 'SearchStudent',
    component: () => import('@/views/admin/SearchStudent.vue')
  }
  ,
  {
    path: '/admin/correct',
    name: 'Correct',
    component: () => import('@/views/admin/Correct.vue')
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/My.vue')
  }
  ,
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  }
  ,
  {
    path: '/admin/classCalendar',
    name: 'ClassCalendar',
    component: () => import('@/views/admin/ClassCalendar.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
